import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { TextInput, Popover } from "orcs-design-system";
import { get, isNumber } from "lodash";
import themeGet from "@styled-system/theme-get";
import { numberToLocaleString } from "src/util/toLocaleString";

import {
  DEFAULT_CONSTRAINT_LABEL,
  DEFAULT_TOTAL_LABEL,
} from "src/allocation/consts";
import {
  ACTIONS,
  SAVE_STATUS,
  useAllocationProjects,
  useLookupData,
} from "../../../context/ForecastContext";
import FailedIndicator from "../../Shared/FailedIndicator";
import useSaveMutation from "../../Shared/useSaveMutation";
import getConstraintPopoverContent from "../../util/getConstraintPopoverContent";
import { pickMutationQuery } from "../constraintInput.util";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Label = styled.label`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${themeGet("fontWeights.2")};
  color: ${themeGet("colors.black")};
  position: absolute;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Total = styled.div`
  height: 36px;
  width: 66px;
  border: solid 1px ${themeGet("colors.greyLight")};
  color: ${themeGet("colors.grey")};
  border-right: 0;
  background-color: ${themeGet("colors.white")};
  border-radius: ${themeGet("radii.2")} 0 0 ${themeGet("radii.2")};
  font-size: ${themeGet("fontSizes.2")};
  text-align: center;
  cursor: default;
  padding-top: 13px;
`;

const Input = styled(TextInput)`
  width: 66px;
  padding: 11px 6px 0 6px;
  text-align: center;
  border-radius: 0 ${themeGet("radii.2")} ${themeGet("radii.2")} 0;
`;

const getGroupId = (grouping) => {
  const { isVirtualDirectConstraintGrouping } = grouping;
  return isVirtualDirectConstraintGrouping
    ? get(grouping, "parent.groupId")
    : get(grouping, "groupId");
};

const TotalOverrideInput = ({
  uniqueId,
  total,
  constraintCell,
  dispatch,
  disabled = false,
  useTargetQuery = false,
}) => {
  const {
    id, // eslint-disable-line
    constraint,
    constraintSaveStatus,
    allocationProjectId,
    grouping,
    isDirectConstraint,
  } = constraintCell;

  const allocationProjects = useAllocationProjects();
  const allocationProject = get(allocationProjects, allocationProjectId);
  const { enableDecimalFTEInput } = allocationProject;
  const { constraintLabel, totalLabel } = useLookupData();
  const groupingConstraintLabel = constraintLabel || DEFAULT_CONSTRAINT_LABEL;
  const groupingTotalLabel = totalLabel || DEFAULT_TOTAL_LABEL;

  // Get forecastTotalAdjustmentPercentage from grouping
  const forecastTotalAdjustmentPercentage = get(
    grouping,
    "forecastTotalAdjustmentPercentage"
  );

  // Check if an adjustment is being applied
  const isAdjustmentApplied =
    isNumber(total) &&
    isNumber(forecastTotalAdjustmentPercentage) &&
    forecastTotalAdjustmentPercentage !== 0;

  const onConstraintInputChange = (newConstraint) => {
    dispatch({
      type: ACTIONS.USER_CONSTRAINT_INPUT_UPDATE,
      cellId: id,
      constraint: newConstraint,
    });
  };

  const onConstraintSaveStatusChange = (status) => {
    dispatch({
      type: ACTIONS.CONSTRAINT_SAVE_STATUS_UPDATE,
      cellId: id,
      status,
    });
  };

  const [updateConstraintValue] = useSaveMutation(
    constraint,
    pickMutationQuery(isDirectConstraint, useTargetQuery),
    onConstraintSaveStatusChange
  );

  const onConstraintInputClick = (e) => {
    e.stopPropagation();
  };

  const onValueChange = ({ floatValue = null }) => {
    if (constraint !== floatValue) {
      onConstraintInputChange(floatValue);
    }
  };

  const onInputBlur = () => {
    const baseVariables = {
      allocationProjectId,
      groupId: getGroupId(grouping),
    };
    const valueVariable = isDirectConstraint
      ? { directConstraint: constraint }
      : { hierarchyConstraint: constraint };
    updateConstraintValue(constraint, { ...baseVariables, ...valueVariable });
  };

  // Giving isValid and isInvalid a string value to remove warnings from React
  const isValid = constraintSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = constraintSaveStatus === SAVE_STATUS.FAILED;
  const popoverContent = getConstraintPopoverContent(constraint, false);

  // Default popover text
  let totalPopoverText = "Automatically calculated sum of all children";

  // Custom popover text when adjustment is applied
  if (isAdjustmentApplied) {
    const adjustmentPercent = 100 + forecastTotalAdjustmentPercentage;
    const unadjustedTotal =
      total / (1 + forecastTotalAdjustmentPercentage / 100);
    totalPopoverText = `Adjusted ${groupingTotalLabel} (${adjustmentPercent}% of ${numberToLocaleString(
      unadjustedTotal
    )} FTE). All totals are adjusted by ${forecastTotalAdjustmentPercentage}% automatically.`;
  }

  const numberProps = {
    allowNegative: false,
    decimalScale: enableDecimalFTEInput ? 2 : 0,
    onValueChange,
  };

  return (
    <>
      <FailedIndicator isFailed={isInvalid} retry={onInputBlur} />
      <Container onClick={onConstraintInputClick}>
        <Popover
          direction="bottomLeft"
          width="250px"
          textAlign="left"
          text={totalPopoverText}
        >
          <Label>{groupingTotalLabel}</Label>
          <Total>{numberToLocaleString(total)}</Total>
        </Popover>
        <Popover
          direction="bottomLeft"
          width="200px"
          textAlign="left"
          text={popoverContent}
        >
          <>
            <Label htmlFor={uniqueId}>{groupingConstraintLabel}</Label>
            <Input
              height="36px"
              id={uniqueId}
              value={constraint}
              data-testid="constraint-input"
              valid={isValid || undefined}
              invalid={isInvalid || undefined}
              numberProps={numberProps}
              onBlur={onInputBlur}
              disabled={disabled}
            />
          </>
        </Popover>
      </Container>
    </>
  );
};

export default TotalOverrideInput;

TotalOverrideInput.propTypes = {
  constraintCell: PropTypes.object,
  dispatch: PropTypes.func,
  total: PropTypes.number,
  uniqueId: PropTypes.string,
  disabled: PropTypes.bool,
  useTargetQuery: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";
import { map, get, isFinite, isNumber } from "lodash";
import { Typography, Flex, Popover } from "orcs-design-system";

import { numberToLocaleString } from "src/util/toLocaleString";
import { getForecastReadonlyFlag } from "src/allocation/util/allocationProject";
import { toReadableNumber } from "src/allocation/util/budgetAndCost";

import {
  useAllocationProjects,
  useColumns,
  useLookupData,
} from "../../../context/ForecastContext";
import TotalFteSum from "../../Shared/TotalFteSum";
import NotificationDot from "../../Shared/NotificationDot";
import useCurrencySymbol from "../../Shared/hooks/useCurrencySymbol";
import ConstraintAlert from "../../Constraint/ConstraintAlert";
import TotalOverrideInput from "../../Constraint/TotalOverrideInput";

const GroupingTotal = ({
  canHaveConstraints,
  canEditConstraints,
  isRootGroupSupply,
  groupId,
  columnId,
  index,
  total,
  constraint,
  dispatch,
  allocationProjects,
  shouldShowCost,
  totalCost,
  isStatic,
  forecastTotalAdjustmentPercentage,
}) => {
  const currencySymbol = useCurrencySymbol();

  // Check if an adjustment is being applied
  const isAdjustmentApplied =
    isNumber(total) &&
    isNumber(forecastTotalAdjustmentPercentage) &&
    forecastTotalAdjustmentPercentage !== 0;

  // Apply adjustment if forecastTotalAdjustmentPercentage is set and total is a number
  const adjustedTotal =
    isNumber(total) && isNumber(forecastTotalAdjustmentPercentage)
      ? total + total * (forecastTotalAdjustmentPercentage / 100)
      : total;

  if (canHaveConstraints && !isRootGroupSupply) {
    const { allocationProjectId } = constraint;
    const allocationProject = get(allocationProjects, allocationProjectId);
    const { enableRequestorForecastConstraint } = allocationProject;
    if (enableRequestorForecastConstraint) {
      // Constraint is not displayed in supply side, so only need to check requestor side
      const isReadonly =
        getForecastReadonlyFlag(allocationProject, {
          isDemand: true,
        }) || !canEditConstraints;
      return (
        <>
          <TotalOverrideInput
            total={adjustedTotal}
            uniqueId={`to-${groupId}-${columnId}-${index}`}
            constraintCell={constraint}
            dispatch={dispatch}
            disabled={isReadonly}
            useTargetQuery
          />
          <ConstraintAlert constraintCell={constraint} total={adjustedTotal} />
        </>
      );
    }
  }

  let header = numberToLocaleString(adjustedTotal);
  if (shouldShowCost && !isStatic && isFinite(totalCost)) {
    header += ` / ${toReadableNumber(totalCost, currencySymbol)}`;
  }

  // Default popover text
  let popoverText = "Automatically calculated sum of all children";

  // Custom popover text when adjustment is applied
  if (isAdjustmentApplied) {
    const adjustmentPercent = 100 + forecastTotalAdjustmentPercentage;
    popoverText = `Adjusted total (${adjustmentPercent}% of ${numberToLocaleString(
      total
    )} FTE). All totals are adjusted by ${forecastTotalAdjustmentPercentage}% automatically.`;
  }

  return (
    <Popover
      direction="bottomLeft"
      width="260px"
      textAlign="left"
      text={popoverText}
    >
      <Typography.H5
        weight="bold"
        className="guided-tour-allocation-forecast-grouping-totals"
      >
        {header}
      </Typography.H5>
    </Popover>
  );
};

GroupingTotal.propTypes = {
  canHaveConstraints: PropTypes.bool.isRequired,
  canEditConstraints: PropTypes.bool.isRequired,
  isRootGroupSupply: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  columnId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  constraint: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  allocationProjects: PropTypes.object.isRequired,
  shouldShowCost: PropTypes.bool,
  totalCost: PropTypes.number,
  isStatic: PropTypes.bool,
  forecastTotalAdjustmentPercentage: PropTypes.number,
};

const GroupingTotals = ({
  grouping,
  dispatch,
  isRootGroupSupply,
  shouldShowCost = false,
}) => {
  const allocationProjects = useAllocationProjects();
  const columns = useColumns();
  const { enableForecastIntersectingFte } = useLookupData();

  const {
    group,
    summedTotals,
    summedTotalFtes,
    notifications,
    canHaveConstraints,
    canEditConstraints,
    forecastTotalAdjustmentPercentage,
    isRoot,
    constraints,
  } = grouping;

  return map(summedTotals, ({ columnId, total, totalCost }, index) => (
    <Flex
      key={columnId}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {enableForecastIntersectingFte && summedTotalFtes && (
        <TotalFteSum fteSum={summedTotalFtes[index]} />
      )}
      <GroupingTotal
        canHaveConstraints={canHaveConstraints}
        canEditConstraints={canEditConstraints}
        isRootGroupSupply={isRootGroupSupply}
        groupId={group.id}
        columnId={columnId}
        index={index}
        total={total}
        constraint={constraints[index]}
        dispatch={dispatch}
        allocationProjects={allocationProjects}
        shouldShowCost={shouldShowCost}
        totalCost={totalCost}
        isStatic={columns[index].allocationProject.isActive}
        forecastTotalAdjustmentPercentage={forecastTotalAdjustmentPercentage}
      />
      <NotificationDot
        notification={get(notifications[index], "notification")}
        isGrouping
        isRootGrouping={isRoot}
      />
    </Flex>
  ));
};

GroupingTotals.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isRootGroupSupply: PropTypes.bool.isRequired,
  shouldShowCost: PropTypes.bool,
};

export default GroupingTotals;
